import React, { useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { useAuth } from "../AuthContext";

import Snackbar from "@mui/material/Snackbar";
import { Form } from "react-final-form";
import SignInForm from "../components/SignInForm";
import userAccountFragment from "../fragments/userAccount";

const LOGIN = gql`
  mutation login(
    $email: String!
    $password: String!
    $rememberMe: Boolean
    $tenant: ID
  ) {
    users {
      signIn(
        email: $email
        password: $password
        rememberMe: $rememberMe
        defaultTenantId: $tenant
      ) {
        user {
          id
          name
          email
        }
        account {
          ...userAccount
        }
        token
        expiresIn
      }
    }
  }

  ${userAccountFragment}
`;

const SignIn = ({ tenant, email, note, continueTo }) => {
  const history = useHistory();
  const { signIn: setSignIn } = useAuth();
  const [login] = useMutation(LOGIN);

  const [showPasswordResetSnackbar, setShowPasswordResetSnackbar] = useState(
    Boolean(note)
  );
  const [error, setError] = useState(null);
  const [initialFormValues] = useState({
    tenant: tenant,
    email: email || "",
  });

  const handleClose = () => {
    setShowPasswordResetSnackbar(null);
  };
  const handleLoginSubmit = async (form) => {
    setError(null);

    try {
      const {
        data: {
          users: { signIn },
        },
      } = await login({
        variables: {
          ...form,
          tenant: localStorage.getItem("tenant_id"),
        },
      });

      if (!signIn.token) {
        throw new Error("Invalid username or password");
      }

      setSignIn(signIn);

      if (signIn.account == null) {
        history.push("/no-accounts");
        return;
      }

      if (signIn.account.tenant.isSuspended) {
        history.push("/suspended");
        return;
      }

      history.push(continueTo || "/");
    } catch (error) {
      console.log("Failed login", error);
      setError(error.message);
    }
  };

  return (
    <Form
      initialValues={initialFormValues}
      onSubmit={handleLoginSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <SignInForm
            authenticating={submitting}
            disabled={submitting}
            error={error}
          />
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={showPasswordResetSnackbar}
            autoHideDuration={6000}
            onClose={handleClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">Password reset</span>}
          />
        </form>
      )}
    />
  );
};

SignIn.propTypes = {
  from: PropTypes.string,
  email: PropTypes.string,
  continueTo: PropTypes.string,
  tenant: PropTypes.string,
};

export default SignIn;
