import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";

import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import INCIDENT_FRAGMENT from "../fragments/incident";

const UPDATE_INCIDENT = gql`
  mutation updateIncident($input: UpdateIncidentInput!) {
    incidents {
      update(input: $input) {
        incident {
          ...incident
        }
      }
    }
  }

  ${INCIDENT_FRAGMENT}
`;

const styles = {
  actions: {
    display: "flex",
  },
};

function UpdateIncident({ initialFormValues, incidentId, render, onUpdated }) {
  const [error, setError] = React.useState(null);
  const [updateIncident] = useMutation(UPDATE_INCIDENT);

  const handleSubmit = (form) => {
    setError(null);

    const attachEvidence = {
      evidence: ((form.attachEvidence || {}).evidence || [])
        .filter((x) => x.filename)
        .map(({ filename, fileKey, contentType, status, description }) => ({
          filename,
          fileKey,
          contentType,
          status,
          description,
        })),
    };

    const variables = {
      input: {
        incidentId,
        ...form,
        attachEvidence,
      },
    };

    if (variables.input.incident?.incidentDate) {
      const {
        input: {
          incident: { incidentDate, incidentTime },
        },
      } = variables;

      variables.input.incident.occurredAt = moment(
        `${incidentDate}T${incidentTime}`
      ).format();

      delete variables.input.incident.incidentDate;
      delete variables.input.incident.incidentTime;
    }

    return updateIncident({ variables }).then(
      () => {
        setError(null);
        onUpdated && onUpdated();
      },
      (err) => {
        console.log("Error submitting form", err);
        setError(err.message);
      }
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
        setValue: ([fieldName, value], state, utils) => {
          utils.changeValue(state, fieldName, () => value);
        },
      }}
      initialValues={initialFormValues}
      render={({ handleSubmit, submitting, form, dirty, values }) => (
        <form onSubmit={handleSubmit}>
          {render({
            handleSubmit,
            submitting,
            dirty,
            form,
            reset: form.reset,
            values,
            error,
          })}
        </form>
      )}
    />
  );
}

UpdateIncident.propTypes = {
  incidentId: PropTypes.string.isRequired,
  initialFormValues: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  onUpdated: PropTypes.func,
};

export default withStyles(styles)(UpdateIncident);
