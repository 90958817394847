import { FrontendApi, Configuration } from "@ory/client";

const basePath = process.env.REACT_APP_ORY_URL || "http://localhost:4000";
const oryClient = new FrontendApi(
  new Configuration({
    basePath,
    baseOptions: {
      withCredentials: true,
    },
  })
);

export default oryClient;
