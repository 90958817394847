import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { useAuth } from "../AuthContext";
import TenantsQuery from "../containers/TenantsQuery";
import SwitchTenantDialog from "../containers/SwitchTenantDialog";
import userAccountFragment from "../fragments/userAccount";

const SWITCH_TENANT = gql`
  mutation SwitchTenant($tenantId: ID!) {
    account {
      switchAccount(tenantId: $tenantId) {
        success
        account {
          ...userAccount
        }
        token
        expiresIn
      }
    }
  }

  ${userAccountFragment}
`;

const SwitchTenantButton = ({ children, onClose }) => {
  const { signIn: setSignIn, user, account, isAuthenticated } = useAuth();
  const currentUserContext = { user, account, isAuthenticated };
  const [switchTenantOpen, setSwitchTenantOpen] = useState(false);
  const [switchTenantMutation] = useMutation(SWITCH_TENANT);
  const [switching, setSwitching] = useState(false);

  const handleSwitchTenantOpen = () => setSwitchTenantOpen(true);
  const handleSwitchTenantClose = () => {
    setSwitchTenantOpen(false);
    onClose && onClose();
  };

  const handleSwitchTenant = async (tenantId) => {
    setSwitching(true);
    try {
      const {
        data: {
          account: { switchAccount },
        },
      } = await switchTenantMutation({
        variables: { tenantId },
      });

      if (!switchAccount.token) {
        throw new Error("Invalid username or password");
      }

      localStorage.setItem("tenant_id", tenantId);

      localStorage.removeItem("filters");

      await setSignIn({
        ...currentUserContext,
        ...switchAccount,
      });

      window.location.href = "/";
    } catch (error) {
      setSwitching(false);
      console.log("Failed login", error);
    }
  };

  const currentTenantId = currentUserContext?.account?.tenant?.id;

  return (
    <TenantsQuery>
      {({ loading, error, data }) => {
        if (!data || !data.allTenants || data.allTenants.totalCount <= 1) {
          return null;
        }

        return (
          <>
            {children({ click: handleSwitchTenantOpen })}
            <SwitchTenantDialog
              switching={switching}
              {...{ loading, data, error }}
              open={switchTenantOpen}
              handleClose={handleSwitchTenantClose}
              currentTenant={currentTenantId}
              handleSwitch={handleSwitchTenant}
            />
          </>
        );
      }}
    </TenantsQuery>
  );
};

SwitchTenantButton.propTypes = {
  children: PropTypes.any.isRequired,
};

export default SwitchTenantButton;
