import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";

import { Form } from "react-final-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";

import CreateMemberForm from "../components/CreateMemberForm";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const INVITE_MEMBER = gql`
  mutation InviteMember($input: InviteUserInputType!) {
    account {
      inviteUser(input: $input) {
        error {
          code
          message
        }
        userGroupIds
      }
    }
  }
`;

const GROUP_QUERY = gql`
  {
    account {
      userGroups {
        all {
          totalCount
          edges {
            cursor
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const CreateMemberDialog = ({ open, fullScreen, handleClose }) => {
  const [error, setError] = useState();
  const [inviteMember] = useMutation(INVITE_MEMBER);
  const { data } = useQuery(GROUP_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    open && setError();
  }, [open]);

  let listGroup;

  const options =
    data &&
    data.account &&
    data.account.userGroups &&
    data.account.userGroups.all &&
    data.account.userGroups.all.edges &&
    data.account.userGroups.all.edges.map(({ node: group }) => {
      return { id: group.id, name: group.name };
    });

  const handleFormSubmit = (form) => {
    if (!listGroup || listGroup.length === 0) {
      setError("Please select a member group.");
      return;
    }

    const variables = {
      input: {
        ...form,
        groups: listGroup || [],
        acceptUrl: `${window.location.origin}/signup`,
      },
    };
    inviteMember({ variables }).then(
      ({
        data: {
          account: {
            inviteUser: { error },
          },
        },
      }) => {
        if (error !== null) {
          const message = `${error.message} (${error.code})`;
          setError(message);
          return;
        }
        setError();
        handleClose();
      }
    );
  };

  const getOptionLabel = (value) => {
    return value && value.name;
  };

  const handleChange = (value) => {
    listGroup =
      value &&
      value.map(({ id }) => {
        return id;
      });
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Form
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Add team member</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Grant access to your team by adding team members to your groups.
                Enter an email address to add an existing account or to invite a
                new user.
              </DialogContentText>
              <CreateMemberForm
                getOptionLabel={getOptionLabel}
                handleChange={handleChange}
                options={options}
              />
              {(error || submitError) && (
                <FormHelperText error={true}>
                  {error || submitError}
                </FormHelperText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={submitting}
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </Button>
              <Button disabled={submitting} type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

CreateMemberDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(CreateMemberDialog);
