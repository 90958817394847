import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import withStyles from "@mui/styles/withStyles";
import { useMutation, useQuery } from "@apollo/client";

import { Typography, Grid, Button } from "@mui/material";
import { Form, Field } from "react-final-form";
import CircularProgress from "@mui/material/CircularProgress";

import Autocomplete from "../components/form/Autocomplete";
import GroupMembersList from "../components/GroupMembersList";
import RemoveMemberDialog from "../containers/RemoveMemberDialog";
import { delay } from "../utils";

const SEARCH_MEMBER = gql`
  query Members($filter: MemberConnectionFilterInputType) {
    account {
      members {
        all(filter: $filter) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
            startCursor
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              name
              lastLoggedIn
              emailAddress
            }
          }
        }
      }
    }
  }
`;

const ADD_MEMBER = gql`
  mutation AddMember($input: AddMemberToUserGroupInputType!) {
    account {
      userGroups {
        addMember(input: $input) {
          error {
            code
            message
          }
          userGroup {
            id
            name
            members {
              totalCount
              edges {
                cursor
                node {
                  id
                  name
                  emailAddress
                }
              }
            }
          }
        }
      }
    }
  }
`;

const INVITE_MEMBER = gql`
  mutation InviteMember($input: InviteUserInputType!) {
    account {
      inviteUser(input: $input) {
        error {
          code
          message
        }
        userGroups {
          id
          name
          members {
            totalCount
            edges {
              cursor
              node {
                id
                name
                emailAddress
              }
            }
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  button: {
    alignSelf: "center",
    textAlign: "center",
  },
  error: {
    color: "red",
  },
  contactMethod: {
    marginLeft: theme.spacing(2),
  },
  form: {
    backgroundColor: "#e6e6e6",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

var delayId;

const GroupMembersTab = ({ classes, userGroupId, members }) => {
  const [open, setOpen] = useState(false);
  const [memberId, setMemberId] = useState();
  const [filter, setFilter] = useState();
  const [membersData, setMemberData] = useState(members);
  const [defaultValues, setDefaultValues] = useState();
  const [error, setError] = useState();
  const [email, setEmail] = useState();

  const [addMember, { loading: adding }] = useMutation(ADD_MEMBER);
  const [inviteMember, { loading: inviting }] = useMutation(INVITE_MEMBER);
  const { data } = useQuery(SEARCH_MEMBER, {
    variables: { filter },
    skip: !Boolean(filter),
    fetchPolicy: "cache-and-network",
  });

  const handleOpenDialog = (id) => {
    setMemberId(id);
    setOpen(true);
  };

  const handleCloseDialog = (data) => {
    setMemberId();
    data && setMemberData(data);
    setOpen(false);
  };

  const handleSearchMember = (event) => {
    let value = event.target.value;
    setEmail(value);
    if (!(event.target.value === undefined)) {
      delayId = delay(
        delayId,
        function () {
          setFilter({ q: value || "" });
        },
        1000
      );
    }
  };

  const options = data?.account?.members?.all?.edges.map(({ node: member }) => {
    return {
      id: member.id,
      name: member.name,
      emailAddress: member.emailAddress,
    };
  });

  const renderOption = (props, value, _) =>
    value && (
      <li {...props}>
        {value.name}
        {value.emailAddress && (
          <small className={classes.contactMethod}>
            ({value.emailAddress})
          </small>
        )}
      </li>
    );

  const getOptionLabel = (value) => {
    let email = value && value.emailAddress && `  (${value.emailAddress})`;
    return value && `${value.name}${email}`;
  };

  const handleChange = (value) => {
    value && setMemberId(value.id);
  };

  const handleAddMember = () => {
    setError();
    const variables = { input: { memberId, userGroupId } };

    if (!Boolean(memberId)) {
      if (!/@/i.test(email)) {
        setError(
          `Please include an '@' in the email address. '${email}' in missing an '@'.`
        );
        setDefaultValues({ id: null, name: "", emailAddress: "" });
        setEmail();
        return;
      }
      inviteMember({
        variables: {
          input: {
            emailAddress: email,
            groups: userGroupId,
            acceptUrl: `${window.location.origin}/signup`,
          },
        },
      }).then(
        ({
          data: {
            account: {
              inviteUser: { userGroups, error },
            },
          },
        }) => {
          if (error !== null) {
            const message = `${error.message} (${error.code})`;
            setError(message);
          } else {
            userGroups && setMemberData(userGroups[0].members);
          }
          setEmail();
        }
      );
    } else
      addMember({ variables }).then(
        ({
          data: {
            account: {
              userGroups: {
                addMember: { userGroup, error },
              },
            },
          },
        }) => {
          if (error !== null) {
            const message = `${error.message} (${error.code})`;
            setError(message);
          } else {
            setMemberData(userGroup.members);
          }
        }
      );
    setFilter();
    setMemberId();
    setEmail();
    setDefaultValues({ id: null, name: "", emailAddress: "" });
  };

  return (
    <Fragment>
      <Typography variant="body2" gutterBottom>
        Grant access to your team by adding users to user groups. You can find
        them by name if users are on your team. If they're not, enter an email
        address to add an existing account or to invite a new user.
      </Typography>
      <Form
        onSubmit={handleAddMember}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} className={classes.form}>
              {defaultValues && (
                <Grid item sm={10} md={10} lg={11}>
                  <Field
                    component={Autocomplete}
                    name="memberId"
                    margin="dense"
                    autoComplete="off"
                    placeholder="Add a user by their name or email address"
                    handleChangeInput={handleSearchMember}
                    handleChange={handleChange}
                    options={options || []}
                    getOptionLabel={getOptionLabel}
                    renderOption={renderOption}
                    defaultValues={defaultValues}
                    fullWidth
                  />
                </Grid>
              )}
              {!defaultValues && (
                <Grid item sm={10} md={10} lg={11}>
                  <Field
                    component={Autocomplete}
                    name="memberId"
                    margin="dense"
                    autoComplete="off"
                    placeholder="Add a user by their name or email address"
                    handleChangeInput={handleSearchMember}
                    handleChange={handleChange}
                    options={options || []}
                    getOptionLabel={getOptionLabel}
                    renderOption={renderOption}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item sm={2} md={2} lg={1} className={classes.button}>
                <Button type="submit" disabled={adding || inviting}>
                  Add
                  {(adding || inviting) && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </Grid>
              {error && (
                <Grid item xs={12} className={classes.error}>
                  {error}
                </Grid>
              )}
            </Grid>
          </form>
        )}
      />

      <GroupMembersList onOpenDialog={handleOpenDialog} members={membersData} />

      <RemoveMemberDialog
        open={open}
        onCloseDialog={handleCloseDialog}
        memberId={memberId}
        userGroupId={userGroupId}
        setMemberData={setMemberData}
      />
    </Fragment>
  );
};

GroupMembersTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupMembersTab);
