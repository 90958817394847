import React from "react";
import { useLocation } from "react-router-dom";
import CenteredPaperLayout from "../components/CenteredPaperLayout";
import AbovePaperLogo from "../components/AbovePaperLogo";

import qs from "querystringify";

import { useAuth } from "../AuthContext";

const LogoutPage = () => {
  const { logout } = useAuth();
  const { search } = useLocation();

  React.useEffect(() => {
    logout(qs.parse(search).continue);
  });

  return (
    <CenteredPaperLayout title="Logging out" above={<AbovePaperLogo />}>
      <p>Please wait while you are logged out of the system...</p>
    </CenteredPaperLayout>
  );
};

export default LogoutPage;
