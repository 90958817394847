import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";

import { execute as recaptcha } from "../recaptcha";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import SignUpForm from "../components/SignUpForm";
import userAccountFragment from "../fragments/userAccount";
const idFastviewLinkPath =
  process.env.REACT_APP_ID_FV_LINK_URL || "http://localhost:3030/";

const errorMessages = {
  200150: "Invalid captcha.",
  200151: "Invalid signup token.",
  200152: "Signup token has expired.",
  200153: "Provided email does not match.",
  200155: "Email is already in use.",
};

const SIGN_UP = gql`
  mutation signUp($input: SignUpInput!) {
    users {
      signUp(input: $input) {
        success
        user {
          id
          name
          email
        }
        account {
          ...userAccount
        }
        token
        expiresIn
        error {
          code
          message
        }
      }
    }
  }

  ${userAccountFragment}
`;

const SIGN_UP_VALIDATION_TICKET = gql`
  mutation signUpValidationTicket($ticket: String!) {
    users {
      signUpValidationTicket(ticket: $ticket) {
        success
        isMigrated
        error {
          code
          message
        }
      }
    }
  }
`;

const SignUp = ({ ticket }) => {
  const [error, setError] = useState(null);
  const [isMigrated, setIsMigrated] = useState(null);
  const [signUp] = useMutation(SIGN_UP);
  const [signUpValidationTicket] = useMutation(SIGN_UP_VALIDATION_TICKET);

  useEffect(() => {
    const handleValidationResponse = async () => {
      try {
        const { data } = await signUpValidationTicket({
          variables: { ticket },
        });
        const result = data?.users?.signUpValidationTicket;

        if (!result?.success) {
          const errorCode = result.error.code;
          const errorMessage = errorMessages[errorCode] || result.error.message;

          setError(`${errorMessage} (${errorCode})`);
        }

        setIsMigrated(result.isMigrated);
      } catch (error) {
        setIsMigrated(false);
        console.error("Error while calling signUpValidationTicket:", error);
      }
    };

    handleValidationResponse();
  }, [ticket, signUpValidationTicket]);

  const handleSignUpSubmit = async (form) => {
    try {
      const captchaResponse = await recaptcha("sign_up");
      const { data } = await signUp({
        variables: { input: { ...form, ticket, captchaResponse } },
      });

      const result = data?.users?.signUp;
      if (result?.success) {
        window.location.replace(
          `${idFastviewLinkPath}login?returnTo=${encodeURIComponent(
            window.location.origin
          )}`
        );
      } else {
        const errorCode = result?.error?.code;
        const errorMessage = errorMessages[errorCode] || result?.error?.message;
        const formattedErrorMessage = `${errorMessage} (${errorCode})`;
        throw new Error(formattedErrorMessage);
      }
    } catch (error) {
      return { [FORM_ERROR]: error.message };
    }
  };

  return (
    <>
      {isMigrated != null ? (
        <Form
          onSubmit={handleSignUpSubmit}
          render={({ handleSubmit, submitting, submitError }) => (
            <form onSubmit={handleSubmit}>
              <Fragment>
                <SignUpForm
                  ticket={ticket}
                  authenticating={submitting}
                  disabled={submitting}
                  error={error || submitError}
                  isMigrated={isMigrated}
                />
              </Fragment>
            </form>
          )}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

SignUp.propTypes = {
  ticket: PropTypes.string.isRequired,
};

export default SignUp;
