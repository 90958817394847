const LOGGED_OUT_PATH = "/";
const idFastviewLinkPath =
  process.env.REACT_APP_ID_FV_LINK_URL || "http://localhost:3030/";

class Auth {
  constructor() {
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.status = this.status.bind(this);
  }

  setSession(authResult) {
    // console.log("set session", authResult);
    // Set the time that the Access Token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("user_id", authResult.userId);
    localStorage.setItem("user", JSON.stringify(authResult.user));
    localStorage.setItem("account", JSON.stringify(authResult.account));
  }

  logout(continueTo) {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user");
    localStorage.removeItem("account");

    // navigate to the home route
    const newPath = continueTo || LOGGED_OUT_PATH;
    // history.replace(newPath);
    window.location.replace(
      `${idFastviewLinkPath}logout?returnTo=${
        window.location.origin
      }${encodeURIComponent(newPath)}`
    );
  }

  isAuthenticated() {
    const token = localStorage.getItem("id_token");
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));

    return token && token !== "undefined" && new Date().getTime() < expiresAt;
  }

  isAuthorized(requiredRoles) {
    if (!requiredRoles) return true;

    const context = this.status();
    var userRole = (context.account && context.account.role) || "";

    if (typeof requiredRoles === "string" && requiredRoles === userRole)
      return true;
    if (requiredRoles.includes(userRole)) return true;
    return false;
  }

  status() {
    const value = {
      isAuthenticated: this.isAuthenticated(),
      userId: localStorage.getItem("user_id"),
      user: JSON.parse(localStorage.getItem("user")),
      account: JSON.parse(localStorage.getItem("account")),
    };

    return value;
  }
}

export default Auth;
