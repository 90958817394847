import gql from "graphql-tag";

const fragment = gql`
  fragment userAccount on SignInAccountType {
    role
    groupPermissions
    tenant {
      id
      name
      isSuspended
      externalLinks {
        name
        url
        iconName
        requiredPermissions
      }
      features {
        isSupportTracking
        nearMissEnabled
        vehiclesEnabled
        incidentsEnabled
        driverReviewEnabled
      }
    }
  }
`;

export default fragment;
